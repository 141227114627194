/**
 * This is a custom component for displaying a left askew image with text
 * to the right
 */
.image-and-text-component {
  padding-top: 4em;
  padding-bottom: 3em;

  .image-and-text-component__container {
    @extend .container;

    .image-and-text-component__row {
      @extend.row;
      @extend .py-md-3;

      position: relative;

      &:before {
        @extend .border;
        @extend .border-warning;

        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-width: 3px !important;

        @include media-breakpoint-up(md) {
          left: 7%;
        }
        @include media-breakpoint-up(lg) {
          left: 10%;
        }
        @include media-breakpoint-down(md) {
          top: -15px;
        }
      }

      .image-and-text-component__col {
        @extend .col-md-6;

        .image-and-text-component__text {
          @extend .py-5;
          @extend .pr-lg-5;

          .image-and-text-component__text-title {
            @extend .mb-4;
            @extend .font-weight-bold;
          }

          .image-and-text-component__text-content {
            @extend .mb-4;
            @extend .text-black;
          }

          .image-and-text-component__text-action {
            @extend .btn;
            @extend .btn-outline-dark ;
          }
        }

        .image-and-text-component__img {
          @extend .px-xl-5;

          @include media-breakpoint-up(md) {
            margin-top: -3.5em;
          }

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
